/* ONLINE CHATBOX custom configuration goes here (www.olark.com/documentation) */
olark.configure('locale.welcome_title', '¿Necesitas ayuda?');
olark.configure('locale.chatting_title', 'Chat online');
olark.configure(
    'locale.welcome_message',
    "Bienvenido, rellena los campos e inicia el chat o busca en <a href='https://www.inwild.es/ayuda/faq' target='_blank'>Preguntas frecuentes</a>"
);
olark.configure('locale.chat_input_text', 'Escriba para iniciar el Chat');
olark.configure(
    'locale.ended_chat_message',
    'Have a question? This conversation has ended. You can start a new chat by sending another message if you like. Thanks for stopping by to chat with us. Come back again anytime.'
);
olark.configure('locale.end_chat_button_text', 'Terminar conversación');

/* OFFLINE CHATBOX custom configuration goes here (www.olark.com/documentation) */
olark.configure('locale.unavailable_title', '¿Necesitas ayuda?');
olark.configure(
    'locale.away_message',
    "Todos nuestros operadores están ocupados en este momento. Si necesitas ayuda puedes completar el módulo de la página <a href='https://www.inwild.es/ayuda#contactUs' target='_blank'>ayuda</a>"
);
olark.configure('locale.name_input_text', 'Nombre');
olark.configure('locale.email_input_text', 'Email');
olark.configure('locale.phone_input_text', 'Teléfono');
olark.configure('locale.offline_note_message', 'Chat offline, deja un mensaje');
olark.configure('locale.send_button_text', 'Enviar');
olark.configure('locale.offline_note_sending_text', 'Enviando...');
olark.configure(
    'locale.offline_note_thankyou_text',
    'Gracias por su mensaje, le contestaremos lo antes posible.'
);
olark.configure(
    'locale.offline_note_error_text',
    'Es necesario completar todos los campos y dejar una dirección de email válida'
);

/* PRE-CHAT SURVEY custom configuration goes here (www.olark.com/documentation) */
olark.configure('locale.name_input_text', 'Nombre');
olark.configure('locale.email_input_text', 'Email');
olark.configure('locale.phone_input_text', 'Teléfono');
olark.configure(
    'locale.introduction_error_text',
    'Es necesario completar todos los campos y dejar una dirección de email válida.'
);
olark.configure(
    'locale.introduction_messages',
    "Bienvenido, rellena los campos e inicia el chat o busca en <a href='https://www.inwild.es/ayuda/faq' target='_blank'>Preguntas frecuentes</a>"
);
olark.configure('locale.introduction_submit_button_text', 'Empezar chat');
olark.configure('locale.prechat_survey_name_label', 'Nombre');
olark.configure('locale.prechat_survey_name_placeholder', 'Nombre');

/* CHAT RATINGS custom configuration goes here (www.olark.com/documentation) */
olark.configure(
    'locale.feedback_survey_question_chat_text',
    '¿Cuál es tu grado de satisfacción con este chat?'
);
olark.configure(
    'locale.feedback_survey_question_operator_intelligence_text',
    '¿Cuál es el nivel de competencia del operador que te atendió en el chat?'
);
olark.configure(
    'locale.feedback_survey_question_operator_speed_text',
    '¿Cuál es el grado de eficiencia del operador que te atendió en el chat?'
);
olark.configure(
    'locale.feedback_survey_question_operator_attitude_text',
    '¿Cuál es el grado de cordialidad del operador que te atendió en el chat?'
);
olark.configure('locale.feedback_survey_question_1_text', 'Pregunta 1 de 5');
olark.configure('locale.feedback_survey_question_2_text', 'Pregunta 2 de 5');
olark.configure('locale.feedback_survey_question_3_text', 'Pregunta 3 de 5');
olark.configure('locale.feedback_survey_question_4_text', 'Pregunta 4 de 5');
olark.configure('locale.feedback_survey_question_5_text', 'Pregunta 5 de 5');
olark.configure(
    'locale.feedback_survey_question_chat_low',
    'Nada satisfecho/a'
);
olark.configure(
    'locale.feedback_survey_question_chat_high',
    'Muy satisfecho/a'
);
olark.configure(
    'locale.feedback_survey_question_operator_intelligence_low',
    'Nada competente'
);
olark.configure(
    'locale.feedback_survey_question_operator_intelligence_high',
    'Muy competente'
);
olark.configure(
    'locale.feedback_survey_question_operator_speed_low',
    'Nada eficiente'
);
olark.configure(
    'locale.feedback_survey_question_operator_speed_high',
    'Muy eficiente'
);
olark.configure(
    'locale.feedback_survey_question_operator_attitude_low',
    'Nada cordial'
);
olark.configure(
    'locale.feedback_survey_question_operator_attitude_high',
    'Muy cordial'
);
olark.configure(
    'locale.feedback_survey_question_additional_feedback_text',
    'Otros feedback'
);
olark.configure('locale.feedback_survey_button_next', 'Siguiente');
olark.configure('locale.feedback_survey_button_submitting', 'Envío');
olark.configure('locale.feedback_survey_button_finish', 'Fin');
olark.configure(
    'locale.feedback_survey_end_message',
    'Gracias por tu feedback'
);
olark.configure(
    'locale.feedback_survey_submission_error_message',
    'Se ha producido un error en el envío de tu pregunta, inténtalo de nuevo.'
);
olark.configure(
    'locale.feedback_survey_begin_button_text',
    'Da una puntuación al chat'
);
olark.configure('locale.feedback_survey_complete_button_text', 'Fin');
olark.configure('locale.feedback_survey_cancel_text', 'Cancelar');
olark.configure(
    'locale.restart_chat_online_button_text',
    'Iniciar la conversación'
);
olark.configure('locale.restart_chat_offline_button_text', 'Email');

/* SEND TRANSCRIPTS custom configuration goes here */
olark.configure('locale.send_transcript_title_text', 'Enviar la transcripción');
olark.configure(
    'locale.send_transcript_begin_button_text',
    'Enviar la transcripción'
);
olark.configure(
    'locale.send_transcript_complete_button_text',
    'Transcripción enviada'
);
olark.configure(
    'locale.send_transcript_error_text',
    'Se ha producido un error en el envío de la transcripción'
);
olark.configure('locale.send_transcript_cancel_text', 'Cancelar');
olark.configure('locale.send_transcript_placeholder', 'Email');

/* OPERATOR custom configuration goes here */
olark.configure('locale.operator_is_typing_text', 'está escribiendo...');
olark.configure(
    'locale.operator_has_stopped_typing_text',
    'ha parado de escribir'
);

olark.configure(
    'locale.disabled_input_text_when_convo_has_ended',
    'El Chat a terminado, actualice la página (F5) para iniciar un nuevo Chat'
);
olark.configure(
    'locale.disabled_panel_text_when_convo_has_ended',
    'La conversación ha terminado, solo tiene que actualizar la página para iniciar una nueva!'
);
